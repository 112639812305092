import Vue from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const messages = {
  EXPIRED_TOKEN: 'Lejárt token.',
  UNDEFINED_TOKEN: 'A kérésben nincs token.',
  INVALID_TOKEN: 'Érvénytelen token.',
  INACTIVE_TOKEN: 'Inaktív token.',
};
const translate = key => messages[key];

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
});

axiosRetry(http, {
  retries: 0,
  retryCondition: error => {
    return (
      error.code !== 'ECONNABORTED' &&
      (!error.response ||
        error.response.status === 401 ||
        (error.response.status >= 500 && error.response.status <= 599))
    );
  },
});

http.interceptors.request.use(
  config => {
    config.headers.common['Session-Token'] = window.vue.$store.state.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async response => {
    if (response.headers['content-type'] !== 'application/json') {
      return response.data;
    }

    // Check variant
    if (response.headers['api-variant'] && response.headers['api-variant'].toLowerCase() !== process.env.VUE_APP_VARIANT) {
      window.vue.$dialog.warning({
        text: `<div class="text-center"><code>${response.headers['api-variant']}</code> (API) ⟷ <code>${process.env.VUE_APP_VARIANT}</code> (APP)<div>`,
        title: 'Nem egyező variáns!',
        actions: {
          false: 'Bezárás'
        },
        waitForResult: false,
      });
    }

    if (response.data.status === 'NO_VALID_API_KEY') {
      await window.vue.$dialog.error({
        text: translate(message) || message || '',
        title: 'Hibaüzenet a szervertől!',
        actions: {
          false: 'Bezárás'
        },
        waitForResult: false,
      });
    } else if (response && response.data.status.endsWith('_TOKEN')) {
      Vue.prototype.$logout({ message: 'A munkaideje lejárt.<br>Kérjük jelentkezzen be újra!' });
    } else if (response && response.data.status !== 'OK') {
      await window.vue.$dialog.error({
        text: response.data,
        title: 'Ismeretlen szerveroldali hiba!',
        actions: {
          false: 'Bezárás'
        },
        waitForResult: false,
      });
      console.error(`[API ERROR]${response.data}`);
      throw Error(response.data);
    }
    if (response.headers['session-token']) {
      window.vue.$store.commit('setToken', response.headers['session-token']);
    }

    return response.data;
  },
  async error => {
    if (!error.response) {
      if (error.code === 'ERR_CANCELED') {
        return Promise.resolve();
      }
      // setTimeout(async () => {
      //   await window.vue.$dialog.error({
      //     text: `Hálózati hiba történt.`,
      //     title: 'Figyelem!',
      //     actions: ['Bezárás'],
      //     waitForResult: false,
      //   });
      // }, 1000);
      // return Promise.reject(error);
    }

    switch (error.response.status) {
      case 401:
        window.vue.$logout('Azonosítási hiba. Kérjük jelentkezzen be újra!');
        return Promise.reject(error);
      case 403:
        await window.vue.$dialog.warning({
          text: `A művelethez nincs jogosultsága.<br>${error.response.data.message || ''}`,
          title: 'Figyelem!',
          actions: {
            false: 'Bezárás'
          },
          waitForResult: false,
        });
        return Promise.reject({ ...error, skipReporting: true });
    }
    let message = error.response ? error.response.data.message : 'Ismeretlen hiba történt';
    let title = 'Hibaüzenet a szervertől!';
    // console.log(error.response.data.status);
    switch (error.response.status) {
      case 404:
        if (error.response.data.status.request) {
          message = `A keresett oldal nem található!<br><pre>${JSON.stringify(
            error.response.data.status.request,
            null,
            2
          )}</pre>`;
        } else {
          message = error.response.data.message;
        }
        break;
    }

    await window.vue.$dialog.error({
      text: translate(message) || message || 'Ismeretlen szerverhiba történt',
      title,
      actions: {
        false: 'Bezárás'
      },
      waitForResult: false,
    });
    return Promise.reject(error);
  }
);

export default http;
