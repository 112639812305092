<template>
  <component
    v-if="field.component === 'VCombobox'"
    :is="field.component"
    :label="field.label"
    v-bind="attrs"
    v-on="$listeners"
    :search-input="value"
    @input="(event) => $emit('input', event)"
  />
  <component
    v-else
    :is="field.component"
    :label="field.label"
    v-bind="attrs"
    v-on="$listeners"
    :value="value"
  />
</template>

<script>
import AsyncCombobox from './AsyncCombobox.vue';
import { VTextField, VTextarea, VCombobox } from 'vuetify/lib/components';
import DatetimePicker from 'uninet-vuetify/src/components/DatetimePicker/DatetimePicker.vue';

export default {
  components: {
    AsyncCombobox,
    VTextField,
    VTextarea,
    VCombobox,
    DatetimePicker,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
    },

    value: {
      required: true,
    },
  },

  data() {
    return {
      attrs: {},
    };
  },

  watch: {
    $attrs: {
      async handler() {
        const defaultFieldAttrs =
          typeof this.field.component_default_attrs === 'string'
            ? JSON.parse(this.field.component_default_attrs)
            : this.field.component_default_attrs;
        const fieldAttrs =
          typeof this.field.attrs === 'string' ? JSON.parse(this.field.attrs) : this.field.attrs;
        this.attrs = { ...defaultFieldAttrs, ...fieldAttrs, ...this.$attrs };
      },
      immediate: true,
    },
  },
};
</script>
