export default {
  namespaced: true,
  state: () => ({
    fields: [],
  }),
  mutations: {
    set(state, fields) {
      state.fields = fields;
    }
  },
  actions: {
    set({ commit }, fields) {
      commit("set", fields);
    }
  },
  getters: {
    findByModelName: (state) => (modelName) => {
      return state.fields
        .filter(e => e.model_name === modelName);
    },

    findByModelNames: (state) => (modelNames) => {
      return state.fields
        .filter(e => modelNames.includes(e.model_name));
    }
  }
};
