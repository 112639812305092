import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import hu from 'vuetify/lib/locale/hu';

Vuetify.config.silent = process.env.NODE_ENV === 'production';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 640,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
    mobileBreakpoint: 720,
  },
  lang: {
    locales: { hu },
    current: 'hu',
  },
  theme: {
    options: { customProperties: true },
    // themes: {
    //   light: {
    //     primary: {
    //       base: colors.indigo.darken1,
    //       darken1: colors.indigo.darken2,
    //     },
    //     secondary: colors.indigo.darken1,
    //     accent: colors.indigo.darken2,
    //     highlight: colors.orange.lighten4,
    //     error: colors.red.accent3,
    //     muted: colors.grey.lighten4,
    //     grey: colors.grey.lighten2
    //   },
    //   dark: {
    //     primary: '#FF9001',
    //     secondary: '#FE7005',
    //     accent: '#364347',
    //     white: colors.grey.darken4,
    //     grey: colors.grey.darken3
    //   }
    // }
    themes: {
      light: {
        primary: {
          base: '#FF9001',
          darken1: '#FE7005',
        },
        secondary: '#FFAE01',
        accent: '#364347',
        highlight: colors.orange.lighten4,
        error: colors.red.accent3,
        muted: colors.grey.lighten4,
      },
      dark: {
        primary: '#FF9001',
        secondary: '#FF9001',
        accent: '#364347',
        white: colors.grey.darken4,
        grey: colors.grey.darken3,
        muted: '#272727',
        success: colors.green.darken3,
      }
    }
  }
});

export { vuetify };
