<template>
  <v-card
    @click="$refs.fileInput.click()"
    @drop.prevent="onDrop"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    v-bind="$attrs"
    :class="{ 'grey': dragover }"
  >
    <v-card-text>
      <input
        ref="fileInput"
        @change="onFileInputChange"
        type="file"
        :multiple="multiple"
        :accept="accept"
        style="display: none"
      />

      <v-row class="d-flex flex-column" dense align="center" justify="center">
        <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60"> mdi-cloud-upload </v-icon>
        <p>{{ label }}</p>
      </v-row>

      <v-virtual-scroll
        v-if="files.length > 0"
        :items="files"
        :height="multiple ? 130 : 60"
        item-height="50"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.name">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span class="ml-3 text--secondary"> {{ sizeUnitFunction(item.size) }}</span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="removeFile(item.name)" icon>
                <v-icon> mdi-close-circle </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider v-if="multiple" />
        </template>
      </v-virtual-scroll>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FileUploadDialog',

  props: {
    label: {
      type: String,
      default: 'Húzza ide a fájlokat, vagy kattintson ide a kiválasztásukhoz!',
    },

    sizeUnitFunction: {
      type: Function,
      default: (e) => `${Math.ceil(e / 10.24) / 100} KB`,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    accept: {
      type: String,
      default: "*",
    },
  },

  data() {
    return {
      dragover: false,
      files: [],
    };
  },

  watch: {
    files() {
      this.$emit('input', this.files);
    }
  },

  methods: {
    removeFile(fileName) {
      // Find the index of the
      const index = this.files.findIndex((file) => file.name === fileName);

      // If file is in uploaded files remove it
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },

    onDrop(e) {
      this.dragover = false;

      // If there are already uploaded files remove them
      if (this.files.length > 0) {
        this.files = [];
      }

      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch('addNotification', {
          message: 'Only one file can be uploaded at a time..',
          colour: 'error',
        });
        return;
      }
      // Add each file to the array of uploaded files
      this.files = Array.from(e.dataTransfer.files);
    },

    onFileInputChange(e) {
      if (this.files.length > 0) {
        this.files = [];
      }

      this.files = Array.from(e.target.files);
    },
  },
};
</script>
