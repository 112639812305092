import Vue from 'vue';

Vue.mixin({
  methods: {
    hasFeature(alias) {
      for (const feature of this.$store.state.features) {
        if (feature.alias === alias && feature.enabled) {
          return true;
        }
      }
      return false;
    },
  },
});
