<template>
  <div id="bg-overlay">
    <Sidebar />
    <Header />
    <v-main app>
      <div id="scroll-container" :style="{
        'height': containerHeight,
      }">
        <v-container class="px-2 px-sm-10" :style="{ 'min-height': containerHeight }" fluid>
          <router-view />
          <v-sheet color="transparent" :height="`calc(${footerHeight} + 40px)`" />
          <Footer />
        </v-container>
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  components: {
    Header: () => import(`@/variants/${process.env.VUE_APP_VARIANT}/components/layout/Header.vue`),
    Footer: () => import(`@/variants/${process.env.VUE_APP_VARIANT}/components/layout/Footer.vue`),
    Sidebar: () => import(`@/variants/${process.env.VUE_APP_VARIANT}/components/layout/Sidebar.vue`),
  },

  computed: {
    headerHeight() {
      return `${process.env.VUE_APP_HEADER_HEIGHT}px`;
    },

    footerHeight() {
      return `${process.env.VUE_APP_FOOTER_HEIGHT}px`;
    },

    containerHeight() {
      return `calc(100vh - ${this.headerHeight})`;
    },

    contentHeight() {
      return `calc(100vh - ${this.headerHeight} - ${this.footerHeight})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  position: relative;
  // min-height: 100vh;
  // max-height: 100vh;
}

#scroll-container {
  position: relative;
  overflow: auto;
}

.v-main {
  height: 100vh;
  // overflow: auto;
  // overflow: auto;
  position: relative;
  // height: 100vh !important;
  //   min-height: 100vh;
  //   max-height: 100vh;
  //   overflow: auto;
}

footer {
  position: absolute;
  left: 0 !important;
  bottom: 0px !important;
  z-index: 0;
}

::-webkit-scrollbar-track {
  margin-top: 640px;
}
</style>
