<template>
  <v-dialog
    :value="$store.state.search"
    @input="(v) => (!v ? $store.commit('closeSearch') : '')"
    max-width="480"
  >
    <v-card>
      <v-card-title>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="keyphrase"
          :loading="loading"
          height="50"
          filled
          placeholder="Keresés..."
          :hint="hint"
          persistent-hint
          autofocus
          clearable
          @input="inputEventHandler"
          @click:clear="
            keyphrase = '';
            inputEventHandler();
          "
        />
      </v-card-title>

      <v-card-text v-if="results.length">
        <v-list>
          <template v-for="(result) in results">
            <v-list-item :key="result.name">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ result.name }} ({{ result.count }} találat)
                </v-list-item-title>

                <v-alert
                  v-if="result.items.length < result.count"
                  type="warning"
                  dense
                  text
                  icon="mdi-information-outline"
                >
                  <span> 100 találat megjelenítve. Szűkítse a keresési feltételeket! </span>
                </v-alert>

                <v-list nav>
                  <v-list-item
                    link
                    v-for="(item) in result.items"
                    :key="item.id"
                    :to="{ name: getRouteName(result.model_name), params: { id: item.id } }"
                    @click="$store.commit('closeSearch')"
                  >
                    <v-list-item-avatar v-if="item.avatar !== undefined">
                      <v-img
                        v-if="item.avatar"
                        :src="`${staticPath}/static/card_owner_photos/${item.avatar}.jpg`"
                      ></v-img>
                      <v-icon v-else>mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="pa-1">
                      <v-list-item-title v-html="item.title" />
                      <v-list-item-subtitle v-if="item.subtitle" v-html="item.subtitle" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      keyphrase: '',
      results: [],
      count: 0,
      loading: false,
      timeout: null,
      staticPath: process.env.VUE_APP_API_URL.replace('/api/', ''),
      abortController: null,
    };
  },

  methods: {
    highlight(text, keyword) {
      const normText = text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const normKeyword = keyword
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      const startIndex = normText.indexOf(normKeyword);
      if (startIndex === -1) {
        return text;
      }
      const endIndex = startIndex + normKeyword.length;
      return (
        text.substring(0, startIndex) +
        '<mark>' +
        text.substring(startIndex, endIndex) +
        '</mark>' +
        text.substring(endIndex)
      );
    },

    inputEventHandler() {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.loading = false;
      if (this.keyphrase?.length < 2) {
        this.results = [];
        clearTimeout(this.timeout);
        return;
      }
      this.timeout = setTimeout(() => {
        this.search();
      }, 500);
    },

    async search() {
      this.loading = true;

      this.abortController = new AbortController();
      const root = this.findRight(this.$store.state.rights, 'ROOT');
      const rights = this.listRights(root.children, { maxDepth: 1 });
      const r = await this.$http.post(
        `search/${this.keyphrase}`,
        { rights: rights.map((e) => e.alias) },
        { signal: this.abortController.signal }
      );
      this.abortController = null;
      if (!r) {
        return;
      }

      this.results = r.results;
      this.count = r.count;

      this.loading = false;

      for (const result of this.results) {
        for (const item of result.items) {
          item.title = this.highlight(item.title, this.keyphrase);
          if (item.subtitle) {
            item.subtitle = this.highlight(item.subtitle, this.keyphrase);
          }
        }
      }

      if (this.results.length) {
        this.$store.commit('openSearch');
      }
    },

    getRouteName(model_name) {
      switch (model_name) {
        case 'AccessLevel':
          return 'AccessLevelEditor';
        case 'Barriers':
          return 'BarrierEditor';
        case 'Buildings':
          return 'BuildingEditor';
        case 'CardOwners':
          return 'CardOwnerEditor';
        case 'Cards':
          return 'CardEditor';
        case 'Connections':
          return 'ConnectionEditor';
        case 'Controllers':
          return 'ControllerEditor';
        case 'Doors':
          return 'DoorEditor';
        case 'Gateways':
          return 'GatewayEditor';
        case 'Groups':
          return 'GroupEditor';
        case 'Plates':
          return 'PlateEditor';
        case 'Readers':
          return 'ReaderEditor';
        case 'Relays':
          return 'RelayEditor';
        case 'Turnstiles':
          return 'TurnstileEditor';
        case 'Users':
          return 'UserEditor';
      }
    },
  },

  computed: {
    hint() {
      if (this.keyphrase?.length < 2) {
        return 'Kérjük, hogy legalább 2 karaktert adjon meg a kereséshez!';
      }
      if (this.loading) {
        return 'Keresés...';
      }
      if (this.count === 0) {
        return 'Nincs találat';
      }
      return `Összesen ${this.count} találat`;
    },
  },

  asyncComputed: {
    elementTypes: {
      async get() {
        const r = await this.$http.get('element-types/list');
        return r.results;
      },
      default: [],
    },
  },
};
</script>
