let optionsSnapshot = null;

import ListFilter from '@/components/ListFilter.vue';

export const dataTablePage = {
  components: {
    ListFilter,
  },

  data() {
    return {
      loading: false,
      dataTable: {
        highlighted: [],
        highlightID: 'id',
        items: [],
        itemsLength: -1,
        options: {
          page: 1,
          itemsPerPage: 100,
          sortBy: [],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false
        },
        headers: [
        ],
      },
      search: {
        timeout: null
      },
      abortController: null,
      optionsSnapshot: {},
    };
  },

  async mounted() {
    if (this.mounted) {
      this.mounted();
    }
    this.parseRouteQueries();
    if (this.fetchItems) {
      this.fetchItems();
    }
  },

  watch: {
    'dataTable.options': {
      handler() {
        if (!this.loading) {
          this.updateRoute();
        }
      },
      immediate: false,
      deep: true,
    }
  },

  methods: {
    parseRouteQueries(saveSnapshot = true) {
      if (this.$route.params.highlight) {
        this.dataTable.highlighted = this.$route.params.highlight;
      }

      if (!Object.keys(this.$route.query).length) {
        return;
      }

      this.dataTable.options.page = Number(this.$route.query.page);
      this.dataTable.options.itemsPerPage = Number(this.$route.query.limit);
      this.dataTable.options.sortBy = [this.$route.query.sortBy];
      this.dataTable.options.sortDesc = [JSON.parse(this.$route.query.sortDesc)];
      if (this.parseSearchQueries) {
        this.search = { ...this.search, ...this.parseSearchQueries() };
      }
      if (saveSnapshot) {
        this.saveOptionsSnapshot();
      }
    },

    saveOptionsSnapshot() {
      optionsSnapshot = JSON.stringify(this.options);
    },

    searchEventHandler() {
      this.abortController?.abort();

      clearTimeout(this.search.timeout);
      this.search.timeout = setTimeout(() => {
        this.dataTable.options.page = 1;
        this.updateRoute();
      }, 1000);
    },

    updateRoute() {
      if (optionsSnapshot && JSON.stringify(this.options) == optionsSnapshot) {
        return;
      }
      this.$router.replace({ name: this.routeName, query: this.options });
      this.saveOptionsSnapshot();
      if (this.fetchItems) {
        this.fetchItems('searchEventHandler');
      }
    },

    getSortName(value) {
      for (const h of this.dataTable.headers) {
        if (h.sortName && h.value === value) {
          return h.sortName;
        }
      }
      return value;
    },

    itemClass(item) {
      return this.dataTable.highlighted.includes(Number(item[this.dataTable.highlightID])) ? 'highlighted' : false;
    }
  },

  computed: {
    options() {
      const page = this.dataTable.itemsLength > -1
        ? Math.min(this.dataTable.options.page, Math.ceil(this.dataTable.itemsLength / this.dataTable.options.itemsPerPage))
        : this.dataTable.options.page;

      let options = {
        page: Math.max(page, 1),
        limit: this.dataTable.options.itemsPerPage || 1,
        sortBy: this.dataTable.options.sortBy[0] || '',
        sortDesc: this.dataTable.options.sortDesc[0] || false,
      };
      return { ...options, ...this.searchQueries };
    },

    postData() {
      const data = this.options;
      data.sortBy = this.getSortName(data.sortBy);
      return data;
    },
  }
}
