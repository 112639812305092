var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{ name: _vm.routeName }}},[_c('v-sheet',{staticClass:"mx-3 my-1 text-body-2",attrs:{"rounded":"","color":"transparent","min-height":"36px"}},[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"menu-item px-3 pa-1 relative d-flex align-center font-weight-medium",class:{
        'active': _vm.isActive,
        'has-children': _vm.hasChildren,
        'grey--text text--lighten-4': !_vm.isActive && _vm.$store.state.darkMode,
        'grey--text text--darken-2': !_vm.isActive && !_vm.$store.state.darkMode,
        dark: _vm.$store.state.darkMode,
      },attrs:{"min-height":"36px","rounded":"","color":"transparent"}},[(!_vm.hideIcon)?_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v(" "+_vm._s(_vm.item.icon)+" ")]):_vm._e(),_c('span',{staticClass:"px-2"},[_vm._v(_vm._s(_vm.item.label))]),(_vm.hasChildren)?_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":"","right":""}},[_c('v-icon',{nativeOn:{"click":function($event){$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()],1),(_vm.hasChildren)?_c('v-sheet',{staticClass:"children ml-5",class:{ open: _vm.open },style:({
        height: _vm.childrenHeight,
      }),attrs:{"rounded":"","color":"transparent"}},_vm._l((_vm.item.children),function(item,index){return _c('sidebar-menu-item',{key:'sidebar-menu-item-' + index,attrs:{"item":item,"hide-icon":""}})}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }