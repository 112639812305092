<template>
  <div>
    <v-avatar
      :size="size"
      :color="color"
      :rounded="rounded"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <v-img
        v-if="hash"
        :src="`${staticPath}/static/card_owner_photos/${hash}_thumb.jpg`"
      />
      <v-icon v-else :size="size * 0.8">mdi-account</v-icon>
    </v-avatar>

    <v-card
        class="picture"
        v-show="hover">
      <v-img
        :src="`${staticPath}/static/card_owner_photos/${hash}.jpg`"
      />
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: null,
    },
    hash: {
      type: String,
      default: null,
    },
    hidePicture: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      hover: false,
      staticPath: process.env.VUE_APP_API_URL.replace('/api/', ''),
    };
  }
};
</script>

<style lang="scss" scoped>
.picture {
  position: absolute;
  width: 160px;
  height: auto;
  z-index: 1000;
  margin-top: -5%;
  margin-left: 5%;
}
</style>
