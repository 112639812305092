import router from '@/router';

const ZoneList = () => import(/* webpackChunkName: "ZoneList" */ '@/pages/zones/List.vue');

const routes = [
  {
    name: 'Login',
    meta: {
      background: 'variants/marcussecurity/assets/login-bg.jpg',
      logo: 'variants/marcussecurity/assets/logo.png',
      subtitle: 'Marcus Security beléptető adminisztrációs felület'
    },
  },
  {
    path: '/info/status',
    component: ZoneList,
    name: 'ZoneListPage',
  },
];

router.$addRoutes(routes);

export default router;
