export const editorPage = {
  data() {
    return {
      loading: false,
      form: {},
      errors: {},
    };
  },

  mounted() {

  },

  computed: {
    fetching() {
      for (const state of Object.values(this.fetchStates)) {
        console.log(state);
        if (!state) {
          return true;
        }
      }
      return false;
    },
  }
}
