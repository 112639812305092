<template>
  <v-tooltip :disabled="!formattedHoverValue" bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        {{ formattedValue }}
      </span>
    </template>
    {{ formattedHoverValue }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },

    field: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      attributes: {},
      formattedValue: '',
      formattedHoverValue: '',
    };
  },

  watch: {
    value: {
      handler() {
        this.formattedValue = this.value;
        const attrs = JSON.parse(this.field.attrs);
        if (this.field.type_name === 'datetime' && attrs.format) {
          this.formattedValue = this.$moment(this.value).format(attrs.format);
        }
        if (this.field.type_name === 'datetime' && attrs.formatHover) {
          this.formattedHoverValue = this.$moment(this.value).format(attrs.formatHover);
        }
        return false;
      },
      immediate: true,
    },
  },

  computed: {
    // formattedValue() {
    //   this.attributes = JSON.parse(this.field.attrs);
    //   if (this.field.type_name === 'datetime' && this.attributes.format) {
    //     return this.$moment(this.value).format(this.attributes.format);
    //   }
    //   return this.value;
    // },
    // formattedHoverValue() {
    //   this.attributes = JSON.parse(this.field.attrs);
    //   if (this.field.type_name === 'datetime' && this.attributes.formatHover) {
    //     return this.$moment(this.value).format(this.attributes.formatHover);
    //   }
    //   return false;
    // },
  },
};
</script>
