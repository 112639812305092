import Vue from 'vue';
import { vuetify } from '@/plugins';

import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.min.css';


Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    actions: {
      false: 'Nem',
      true: 'Igen',
    },
    icon: 'mdi-help-circle-outline',
  },
  notification: {
    timeout: 6000,
    position: 'top-right',
  },
});

export { VuetifyDialog };

let _dialogs = [];

Vue.prototype.$dialog.on('shown', ({ dialog }) => {
  _dialogs.forEach((_dialog) => _dialog.remove());
  _dialogs.push(dialog);
})

Vue.prototype.$dialog.on(
  'destroyed',
  ({ dialog }) => (_dialogs = _dialogs.filter((_dialog) => _dialog.id !== dialog.id))
)

Vue.prototype.$dialog.clearDialogs = () => {
  _dialogs.forEach((_dialog) => _dialog.remove());
  _dialogs = [];
}
