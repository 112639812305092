<template>
  <v-app :style="`background-image: url('${background}')`">
    <router-view />
    <search v-if="$store.state.user" />
  </v-app>
</template>

<script>
import Search from '@/components/Search.vue';


const darkBackground = require('@/assets/img/bg-dark.jpg');
const lightBackground = require('@/assets/img/bg-light.jpg');

export default {
  components: {
    Search,
  },

  data() {
    return {
      keyPressed: {},
    };
  },

  mounted() {
    this.$vuetify.theme.dark = this.$store.state.darkMode;
    window.addEventListener('keyup', this.keyUpEventHandler);
    window.addEventListener('keydown', this.keyDownEventHandler);
  },

  destroyed() {
    window.removeEventListener('keyup', this.keyUpEventHandler);
    window.removeEventListener('keydown', this.keyDownEventHandler);
  },

  methods: {
    keyDownEventHandler(e) {
      this.keyPressed[e.key + e.location] = true;
      if (
        this.keyPressed.F0 == true &&
        this.keyPressed.Shift1 == true &&
        this.keyPressed.Control1 == true
      ) {
        this.$store.commit('openSearch');
        this.keyPressed = {}; // reset key map
      }
    },

    keyUpEventHandler(e) {
      this.keyPressed[e.key + e.location] = false;
      this.keyPressed = {};
    },
  },

  computed: {
    background() {
      return this.$store.state.darkMode
        ? darkBackground
        : lightBackground;
    },
  }
};
</script>


<style src="@/assets/fonts/materialdesignicons/materialdesignicons.min.css"></style>
<style lang="scss" src="@/style.scss"></style>