<template>
  <v-card class="card" elevation="1">
    <v-card-title>
      <v-icon v-if="icon" left>
        {{ icon }}
      </v-icon>
      {{ title }}
    </v-card-title>
    <v-card-subtitle> </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col v-for="(stat, index) in statistics" :key="`stat-${index}`">
          <component :is="stat.to ? 'router-link' : 'div'" :class="{ disabled: !stat.to }" :to="stat.to ?? { }">
            <v-sheet color="transparent" height="50" class="d-flex align-center justify-center">
              <div class="text-h4 font-weight-regular text-center" v-if="stat.value !== undefined">
                {{ stat.value }}<span v-if="stat.unit" class="text-subtitle-1">{{ stat.unit }}</span>
              </div>
              <v-progress-circular v-else indeterminate />
            </v-sheet>
            <div class="text-caption text-center text--text">{{ stat.label }}</div>
          </component>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    statistics: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
